<script>
  import layout from "@/components/art/LayoutError.vue";
  export default {
    components: { layout },
    methods: {
      onSubmit() {
        this.$router.push("/apps");
      },
    },
  };
</script>

<template>
  <layout @onSubmit="onSubmit">
    <template v-slot:title>
      <div>
        Lo sentimos no se encuentra en ninguna de nuestras sedes.
      </div>
    </template>
    <template v-slot:body>
      <div>
        Una vez te encuentres ubicado en alguna de nuestras sedes podrás acceder sin problema.
      </div>
    </template>
  </layout>
</template>
