<script>
  import art from "@/components/art/art.vue";

  export default {
    components: { art },
    data() {
      return {
        timeOut: null,
      };
    },
    computed: {
      year() {
        const currentDate = new Date();
        return currentDate.getFullYear();
      },
    },
    methods: {
      onClick() {
        this.$emit("onSubmit");
      },
    },
    beforeMount() {
      clearTimeout(this.timeOut);
      this.timeOut = setTimeout(this.onClick, 5000);
    },
  };
</script>

<template>
  <main class="notAllowed">
    <div class="notAllowed__card">
      <art class="notAllowed__top" dir="top" />
      <div class="notAllowed__body">
        <div class="notAllowed__inner">
          <h2 class="notAllowed__title">
            <slot name="title"></slot>
          </h2>
          <p class="notAllowed__msg">
            <slot name="body"></slot>
          </p>
          <img class="notAllowed__icon" src="../../assets/img/alert.svg" alt="alert" />
        </div>
        <a class="notAllowed__btn" @click="onClick">Volver</a>
      </div>
      <art dir="bottom" class="notAllowed__bottom" />
    </div>
    <div class="notAllowed__footer">
      <div class="notAllowed__locations">
        <p>Medellín - Pereira - Cúcuta - Bogotá - Global</p>
      </div>
      <div class="notAllowed__reservedRight">
        <p>Models1A © Todos los derechos reservados {{ this.year }}</p>
      </div>
    </div>
  </main>
</template>

<style lang="scss">
  .notAllowed {
    text-align: center;
    width: 100vw;
    height: 100vh;
    @include Flex(column, center, center);
    background-image: url("../../assets/img/Fondo.png");
    background-repeat: no-repeat;
    background-size: cover;

    &__card {
      position: relative;
      width: 90vw;
      height: 80vh;
      background-color: #fff;
      border-radius: 20px;
      box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
      @include Flex(column, center, center);
      margin-bottom: 24px;
      overflow: hidden;
    }

    &__inner {
      max-width: 500px;
    }

    &__msg {
      width: 80%;
      margin: $mpadding * 1.5 auto;
      font-family: $newFont_regular;
      size: 16px;
      font-weight: 400;
      position: relative;
      z-index: 10;
    }

    &__title {
      font-family: $newFont_bold;
      font-size: 20px;
      font-weight: 700;
      position: relative;
      z-index: 10;
      padding: 0px 12px;
    }

    &__btn {
      @include ButtonRed();
      position: relative;
      padding: 10px 80px;
      cursor: pointer;
      border-radius: 8px;
      font-family: $newFont_regular;
      z-index: 10;
      top: 50px;

      &:hover {
        text-decoration: none;
        color: $white;
      }

      &__footer {
        @include Flex(column, center, center);
        width: 90vw;
        gap: 4px;
        text-align: center;
      }
    }

    &__top,
    &__bottom {
      position: absolute;
      width: 100%;
      height: 10%;
      transform: scale(0.7);
      z-index: 1;
    }

    &__bottom {
      right: 0;
      width: 60%;
      left: 35%;
      z-index: 1;
    }

    &__icon {
      width: 150px;
    }

    &__locations,
    &__reservedRight {
      font-family: $newFont_regular;
    }

    @include tabletWidth() {
      &__footer {
        width: 90vw;
        max-width: 1400px;
        @include Flex(row, space-between, center);
        padding: 0px 48px;
        gap: 18px;
      }

      &__body {
        margin-left: 30%;
      }

      &__inner {
        max-width: 500px;
        margin-bottom: 20%;
      }

      &__btn {
        top: -40px;
      }

      &__top {
        left: 200px;
        width: 40%;
      }

      &__bottom {
        left: 55%;
        margin: 0px 0px 10px 0px;
        width: 20%;
      }

      &__icon {
        width: 200px;
        position: absolute;
        left: 10%;
        bottom: 37%;
      }
    }

    @include screen_tv() {
      &__card {
        width: 100%;
        min-width: 300px;
        max-width: 1350px;
        height: 70%;
      }

      &__bottom {
        left: 65%;
      }

      &__msg {
        width: 100%;
      }

      &__icon {
        width: 250px;
        position: absolute;
        left: 10%;
        bottom: 50%;
      }
    }

    @media (min-width: 1200px) {
      &__icon {
        width: 200px;
        position: absolute;
        left: 30%;
        bottom: 37%;
      }
    }
  }
</style>
