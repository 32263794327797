<template>
  <div class="art" :dir="dir">
    <div class="art-content">
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dir: { String, default: "top" },
  },
};
</script>

<style lang="scss">
.art {
  position: absolute;
  width: 100%;
  left: 0;
  pointer-events: none;
  &[dir="top"] {
    top: -60px;
  }
  &[dir="bottom"] {
    bottom: -60px;
    transform: scaleX(-1) rotateX(180deg);
  }
  .line {
    background: #ffcfcf;
    border-radius: 4pc;
    transform: rotate(145deg);
    position: relative;
    &:nth-child(1) {
      width: 100%;
      height: 80px;
    }
    &:nth-child(2) {
      width: 100%;
      height: 50px;
      left: 120px;
      top: -72px;
    }
    &:nth-child(3) {
      width: 100%;
      height: 50px;
      left: 225px;
      top: -105px;
    }
    &:nth-child(4) {
      width: 50px;
      height: 50px;
      left: 25px;
      top: 0px;
    }
  }
  @media (min-width: $tablet_width) {
    .line {
      &:nth-child(4) {
        top: $mpadding * 2;
      }
    }
  }
}
</style>
